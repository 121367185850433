import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Item, Props as ItemProps } from 'app/components/OffersSlider/Item'
import React, { memo } from 'react'

export interface Props {
  offers: ItemProps[]
}

export const OffersList = memo(function OffersList({ offers }: Props) {
  if (offers.length < 1) {
    return null
  }

  return (
    <Container>
      <Wrapper row wrap>
        {offers.map((item, index) => (
          <Item key={index} {...item} />
        ))}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin-bottom: 9rem;
  padding: 0 10.972vw;

  @media (max-width: 1199px) {
    margin-bottom: 5.625rem;
    padding: 0 1.875rem;
  }
`

const Wrapper = styled(FlexBox)`
  margin-right: -4.5rem;

  > a {
    width: calc(50% - 4.5rem);
    margin: 4.5rem 4.5rem 0 0;
  }

  @media (max-width: 1199px) {
    margin-right: -1.5rem;

    > a {
      width: calc(50% - 1.5rem);
      margin: 1.5rem 1.5rem 0 0;
    }
  }

  @media (max-width: 767px) {
    margin-right: 0;
    > a {
      width: 100%;
      margin-right: 0;
    }
  }
`
