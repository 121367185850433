import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Diamond } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  list?: string[]
  title?: string
}

export const Benefits = memo(function Benefits({ list, title }: Props) {
  if (!list) {
    return null
  }

  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title className="wave-light">{title}</Title>
        </FadeInUp>
      ) : null}

      <List dial={2} row space="between" wrap>
        {list.map((item: any, index) => (
          <Item key={index}>
            <Diamond />

            <Label>{item.label}</Label>
          </Item>
        ))}
      </List>
    </Container>
  )
})

const Container = styled.div`
  background: ${({ theme }) => theme.colors.variants.primaryDark3};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 9rem 12.639vw 7.875rem;

  @media (max-width: 1199px) {
    padding: 3.75rem 1.875rem;
  }
`

const Title = styled.h2`
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 2.8125rem;
  &:after {
    flex: 1;
    margin: 0 0 0 3.75rem;
  }

  @media (max-width: 1199px) {
    &:after {
      display: none !important;
    }
  }

  @media (max-width: 767px) {
    justify-content: center;
    text-align: center;
  }
`

const List = styled(FlexBox)`
  max-width: 51.25rem;
  margin: 4.5rem auto 0;

  @media (max-width: 1199px) {
    max-width: none;
    margin-top: 1.25rem;
  }

  @media (max-width: 767px) {
    display: block;
  }
`

const Item = styled.div`
  svg {
    display: block;
    width: auto;
    height: 2.25rem;
    fill: none;
    margin: 0 auto 1.25rem;
    stroke: ${({ theme }) => theme.colors.variants.primaryLight1};
  }

  @media (max-width: 1199px) {
    margin-top: 2.5rem;
  }
`

const Label = styled.div`
  max-width: 10.625rem;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  line-height: 1.125rem;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 767px) {
    max-width: none;
  }
`
